import React from 'react';
import ReactDOM from 'react-dom';
import { Container, Hero, HeroBody, Title } from 'bloomer';
import registerServiceWorker from './registerServiceWorker';

import './index.scss';

const title = () => {
  const { hostname } = window.location;
  const parts = hostname.split('.');
  const target = (parts[0] === 'www' ? parts[1] : parts[0]).toLowerCase();

  switch (target) {
    case 'tyg':
      return 'TYG Resources Pte Ltd';

    case 'koa':
      return 'Koa';

    case 'peh':
      return 'Peh';

    case 'cjcouncil':
      return 'CJC Student Council';

    case 'punchline':
      return 'punchline.';

    case 'gcp':
      return 'Cloud Platform';

    case 'duktiv':
      return 'Duktiv';

    case 'boonping':
      return 'Boon Ping';

    case 'localfocal':
      return 'Local Focal';

    case 'zhang':
      return 'Zhang';

    case 'xwgate':
      return 'XWGate - an archived FYP project.';

    default:
      return 'Hosted by koa & co.';
  }
}

const finalTitle = title();

document.title = finalTitle;

ReactDOM.render(
  <Hero isColor="black" isFullHeight>
    <HeroBody>
      <Container hasTextAlign='centered'>
        <Title>{finalTitle}</Title>
      </Container>
    </HeroBody>
  </Hero>
  , document.getElementById('root'));
registerServiceWorker();
